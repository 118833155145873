<template>
  <!-- Error page-->
  <div class="misc-wrapper not-found-page">
    <b-link class="brand-logo">
      <DefaultLogo />
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">{{ $t("PageNotFound") }}</h2>
        <p class="mb-2">
          {{ $t("Oops") }}
        </p>

        <b-button variant="primary" class="mb-2 btn-sm-block" @click="goHome">
          {{ $t("BackToHome") }}
        </b-button>

        <!-- image -->
        <ErrorImg />
      </div>
    </div>
  </div>
  <!-- / Error page-->
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from "bootstrap-vue";
import DefaultLogo from "@/views/Common/DefaultLogo.vue";
import store from "@/store/index";
import ErrorImg from "@/assets/images/pages/error.svg";
export default {
  components: {
    DefaultLogo,
    BLink,
    BButton,
    BImg,
    ErrorImg
  },
  data() {
    return {
      downImg: require("@/assets/images/pages/error.svg")
    };
  },
  computed: {},
  methods: {
    goHome() {
      this.$router.push({ name: "login" }).catch((err) => {
        this.$router.push({ name: "login" }).catch((err) => {});
      });
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-misc.scss";
@media only screen and (max-width: 667px) {
  .not-found-page {
    margin-top: 4rem;
  }
}
</style>
